<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  export default {
    mounted() {
      // window.onresize = () => {
      //   // 设置基准值
      //   let sUserAgent = navigator.userAgent.toLowerCase()
      //   let bIsWM = /mobile/.test(sUserAgent)
      //   let baseFontSize = document.documentElement.clientWidth / 375 * 16; // 这里假设设计图宽度为375px，所以基准值为20px
      //   !bIsWM && (baseFontSize = 32)
      //   document.documentElement.style.fontSize = `${baseFontSize}px`;
      // }
    },
    destroyed() {
      localStorage.removeItem('language')
    }
  }
</script>

<style>
@import "@/assets/css/style.css";
</style>
