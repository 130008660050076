export default {
    'HeaderNav1': 'Request a Parking Bay',
    'HeaderNav2': 'Report to Zipp',
    'HeaderNav3': 'Contact',
    'HomeHeroTitle1': 'Mobility',
    'HomeHeroTitle2': 'done right.',
    'HomeHeroText': 'Zipp is Ireland\'s leading micromobility provider. Download the Zipp app, find a Zipp and\n' +
        '          start Zipping!',
    'HomeAboutText1': 'Shared micromobility can have its faults. ',
    'HomeAboutText2': 'Our “mobility done right” approach solves them. ',
    'HomeAboutText3': 'We know how to meet the needs of all a city\'s stakeholders.',
    'HomeHowTitle': 'How to Zipp.',
    'HomeHowText1': 'Download the app.',
    'HomeHowText2': 'Locate a scooter or bike.',
    'HomeHowText3': 'Scan the QR code and Zipp away!',
    'HomeCommTitle': 'What\'s different about Zipp?',
    'HomeCommText1': 'We can guarantee optimal outcomes in sustainability, safety and parking through our\n' +
        '        innovative approach to shared micromobility. This approach rests on a few key principles.',
    'HomeCommText2': 'Community-Centric',
    'HomeCommText3': 'Sustainability First',
    'HomeCommText4': 'Micromobility for All',
    'HomeCommText5': 'No Outsourcing',
    'HomeCommText6': 'World Class Technology',
    'HomeCommText7': 'Sustainable Growth',
    'HomeCommBtn': 'Show More',
    'HomeContactTitle': 'Get in Touch.',
    'HomeContactText1': '',
    'HomeContactText2': 'Belfield Innovation Park,',
    'HomeContactText3': '',
    'HomeContactText4': '',
    'HomeContactText5': 'Ireland',
    'HomeContactText6': 'Name',
    'HomeContactText7': 'Email',
    'HomeContactText8': 'Message',
    'FooterCopyRight': 'Copyright © 2021 Zipp Mobility - All Rights Reserved.',
    'FooterTitle1': 'Complaints Policy',
    'FooterTitle2': 'Rider Agreement',
    'ParkingTitle': 'Request a Parking Bay',
    'ParkingText1': 'We are always on the lookout for new parking locations for our scooters. If you would like to suggest a location for us to place scooters, leave your details and your suggested location below. We recommend using',
    'ParkingText2': 'what3words',
    'ParkingText3': 'to identify your proposed location! Please note that all parking bays must go through an approval process that includes multiple local stakeholders so we can not guarantee all requests will be satisfied.',
    'ParkingPlaceholder1': 'Name (required)',
    'ParkingPlaceholder2': 'Email (required)',
    'ParkingPlaceholder3': 'what3words location or address  and description of proposed parking bay (required)',
    'ParkingPlaceholder4': 'Any other comments (optional)',
    'SubmitError': 'Oops! Something went wrong while submitting the form.',
    'SubmitSuccess': 'Thank you! Your submission has been received!',
    'SubmitBtn': 'Submit',
    'ExportTitle': 'Request a Parking Bay',
    'ExportText1': 'We are always on the lookout for new parking locations for our scooters. If you would like to suggest a location for us to place scooters, leave your details and your suggested location below. We recommend using',
    'ExportPlaceholder1': 'Name (required)',
    'ExportPlaceholder2': 'Email (required)',
    'ExportPlaceholder3': 'what3words location or address  and description of proposed parking bay (required)',
    'ExportPlaceholder4': 'Any other comments (optional)',
    'PolicyTitle': 'Complaints Policy',
    'PolicyTitle1': 'When To Use This Policy',
    'PolicyTitle2': 'How To Submit a Complaint',
    'PolicyTitle3': 'What Should You Include In Your Complaint',
    'PolicyTitle4': 'Dealing With Your Complaint',
    'PolicyTitle5': 'Outcome',
    'PolicyTitle6': 'Putting Things Right',
    'PolicyTitle7': 'Learning Lessons',
    'PolicyTitle8': 'What We Expect From You',
    'PolicyText1': 'When you complain to us, we will usually respond in the way we explain below. Sometimes, you might be concerned about matters that are not decided by us (e.g. things under the control of our local government partners or national government regulators) and we will then advise you about how to make your concerns known.',
    'PolicyText2': 'You can make a complaint in any of the ways below:',
    'PolicyText3': 'You can make a complaint using the form on our website at www.zippmobility.com.',
    'PolicyText4': 'You can use the customer support function in our app.',
    'PolicyText5': 'You can email us at info@zippmobility.com.',
    'PolicyText6': 'Remember to state your name, address and telephone number (and email, if applicable) and whether you are acting on behalf of someone else.',
    'PolicyText7': 'Briefly describe what your complaint is about stating relevant locations, the vehicle number, dates and times, if applicable.',
    'PolicyText8': 'List your specific concerns starting with the most important concern.',
    'PolicyText9': 'Be clear about what you are hoping to achieve (for example a refund, apology, explanation, etc.).',
    'PolicyText10': 'State your preferred method of communication.',
    'PolicyText11': 'These details are needed to handle your complaint.',
    'PolicyText12': 'We will formally acknowledge your complaint within two working days and let you know how we intend to deal with it. We will deal with your complaint in an open and honest way. If you are making a complaint on behalf of somebody else, we will need their agreement to you acting on their behalf.',
    'PolicyText13': 'After we have investigated your complaint, we will let you know our conclusions. We will explain how and why we came to our conclusions. If we find that Zipp were at fault, we will tell you what and why it happened.',
    'PolicyText14': 'If we didn’t do something well, we will aim to put it right. If you have lost out as a result of a mistake on our part we will try to put you back in the position you would have been in if we had got it right.  ',
    'PolicyText15': 'We take your complaints seriously and try to learn from any mistakes we have made. Our senior management team considers a summary of all complaints on a regular basis as well as details of any serious complaints. Where there is a need for change, we will develop an action plan setting out what we will do, who will do it and when we plan to do it by.',
    'PolicyText16': 'In times of trouble or distress, some people may act out of character. There may have been upsetting or distressing circumstances leading up to a complaint. We do not view behaviour as unacceptable just because someone is forceful or determined. We believe that all complainants have the right to be heard, understood and respected. However, we also consider that our staff have the same rights. We, therefore, expect you to be polite and courteous in your dealings with us. We will not tolerate aggressive or abusive behaviour, unreasonable demands or unreasonable persistence.',
    'RiderTitle': 'Zipp Rider Agreement',
    'RiderTitle1': 'Definitions',
    'RiderTitle2': 'Use of the Services',
    'RiderTitle3': 'Payment and Fees',
    'RiderTitle4': 'Additional Terms of Use',
    'RiderTitle5': 'Notices',
    'RiderTitle6': 'Governing Law',
    'RiderTitle7': 'Dispute Resolution',
    'RiderTitle8': 'Waiver and Severability',
    'RiderTitle9': 'Releases; Disclaimers;',
    'RiderTitle10': 'Assumption of Risk',
    'RiderText1': 'This Rider Agreement (“',
    'RiderText2': 'Agreement',
    'RiderText3': '”)governs the contractual relationship\n' +
        '      between ZIPP MOBILITY LIMITED of Nova UCD, University College Dublin, Stillorgan Road, Belfield, Dublin (“Zipp”)\n' +
        '      and you, the user (“Rider”) for the rental and use of electric mobility vehicles (a “',
    'RiderText4': 'Vehicle',
    'RiderText5': '”)(the\n' +
        '      “',
    'RiderText6': 'Services',
    'RiderText7': '”).',
    'RiderText8': 'Zipp agrees to let, and the Rider expressly agrees to take on, rental of the\n' +
        '      Vehicle subject to the terms and conditions set out herein.',
    'RiderText9': '“App” means the mobile application owned and managed by Zipp relating to the Services;',
    'RiderText10': '“Zipp\n' +
        '      Equipment” means any equipment, including the Vehicle, owned by Zipp in connection with the Services;',
    'RiderText11': 'Zipp and the Rider are the only parties to this Agreement.  Except\n' +
        '      as expressly permitted by this Agreement:',
    'RiderText12': 'The Rider is the sole renter and is solely responsible\n' +
        '      for compliance with all terms and conditions contained herein; ',
    'RiderText13': 'Once a Vehicle is activated\n' +
        '      by a Rider, the Vehicle must be used only by the Rider; and',
    'RiderText14': 'The Rider must not allow others to\n' +
        '      use a Vehicle that the Rider has activated.',
    'RiderText15': 'The Rider represents and\n' +
        '      certifies that he/she is at least 16 years of age and has a current and valid provisional or full driver’s\n' +
        '      licence.',
    'RiderText16': 'The Rider represents and certifies that he/she is familiar\n' +
        '      with the operation of the Vehicle, is reasonably competent and physically fit to ride the Vehicle, and has\n' +
        '      reviewed the safety materials provided by Zipp via the App and/or other materials. By choosing to ride a Vehicle,\n' +
        '      the Rider assumes all responsibilities and risks for any injuries or medical conditions. The Rider is solely\n' +
        '      responsible for determining whether conditions, including, without limitation, rain, fog, snow, hail, ice, heat or\n' +
        '      electrical storms, make it dangerous to operate a Vehicle.',
    'RiderText17': 'The Rider agrees that the Vehicle and any Zipp Equipment attached there to, at all times, remain the exclusive property of\n' +
        '      Zipp and/or its lessors/licensors. The Rider mustn\'t dismantle, write on, or otherwise modify, repair or deface a\n' +
        '      Vehicle, any part of a Vehicle, or other Zipp Equipment in any way.  The Rider must not write on, peel, or\n' +
        '      otherwise modify or deface any sticker on a Vehicle in anyway. The Rider must not use a Vehicle, or other Zipp\n' +
        '      Equipment, for any advertising or other commercial purpose without the express written permission of\n' +
        '      Zipp.',
    'RiderText18': 'The Rider agrees and acknowledges that the Vehicles may not be\n' +
        '      available 24 hours a day,7 days/week, 365 days per year.  Vehicles must be rented during operating hours and\n' +
        '      within the maximum rental time limits set forth below.  The number of Vehicles are limited and Vehicle\n' +
        '      availability is never guaranteed. The Rider agrees that Zipp may require the Rider to return a Vehicle at any\n' +
        '      time.',
    'RiderText19': 'The Rider agrees not to use, operate, and/or ride the Vehicle outside permitted service areas, and further agrees not to move or transport the Vehicle except as expressly\n' +
        '      authorized by Zipp.',
    'RiderText20': 'The Rider agrees to follow all laws pertaining to the use, riding, parking, charging, and/or operation of the Vehicle, including all local laws and the rules and\n' +
        '      regulations pertaining to Vehicles in the area where the Rider operates the Vehicle, including any helmet laws.\n' +
        '      The Rider also agrees to act with courtesy and respect toward others while using the Services.',
    'RiderText21': 'The Rider agrees to the following:',
    'RiderText22': 'Zipp recommends against operation of a Vehicle while carrying or holding a briefcase, backpack, bag, or other item that can alter\n' +
        '      balance, add extra weight, or impair safe operation of the Vehicle.  If the Rider chooses to use such an\n' +
        '      item, he/she dos so at his/her own peril; Zipp recommends that the Rider ensures the item fits snugly to his/her\n' +
        '      body or is otherwise secured, and does not impede the Rider’s ability to operate the Vehicle safely.',
    'RiderText23': 'The Rider must not place any objects on the handlebar of the Vehicle, such as backpacks or bags.',
    'RiderText24': 'While riding a Vehicle, the Rider must not use any cellular telephone, text messaging device, portable music player, or\n' +
        '      other device that may distract the Rider from operating the Vehicle safely.',
    'RiderText25': 'The Rider must not operate a Vehicle while under the influence of any alcohol, drugs, medication, or other substance\n' +
        '      that may impair his/her ability to operate a Vehicle safely.',
    'RiderText26': 'The Rider must not carry a second person or child on a Vehicle.',
    'RiderText27': 'The Rider may only use locking mechanisms provided by Zipp.  The Rider may not add another lock to the Vehicle or\n' +
        '      lock a Vehicle other than in accordance with Zipp’s instructions.',
    'RiderText28': 'The Vehicle must not be parked at a prohibited parking spot. The Vehicle cannot be parked on unauthorized private\n' +
        '      property, in a locked area, blocking the right of way, or in any other unapproved non-public space.  The\n' +
        '      Rider should not park the Vehicle in heavily trafficked areas if the Vehicle is in danger of being knocked\n' +
        '      down.',
    'RiderText29': 'The Vehicle must be parked in a space hat is visible, and in an upright position using the kickstand.',
    'RiderText30': 'The Rider agrees that he/she will not use the Vehicle for\n' +
        '      racing, mountain riding, or stunt or trick riding.  The Rider agrees that he/she will not operate and/or use\n' +
        '      the Vehicle on unpaved roads, through water (beyond normal urban riding), or in any location that is prohibited,\n' +
        '      illegal, and/or a nuisance to others.  The Rider agrees that he/she will not use the Vehicle for hire or\n' +
        '      reward, nor use it in violation of any law, ordinance or regulation.',
    'RiderText31': 'The Rider must not exceed the maximum weight limit for the Vehicle.',
    'RiderText32': 'The Rider must not tamper with, attempt to gain unauthorized access to, or otherwise use the Services other than as specified in this Agreement.',
    'RiderText33': 'The Rider must report any accident, crash, damage, personal injury traffic\n' +
        '      violation, or stolen or lost Vehicle to Zipp as soon as possible.  If a crash involves personal injury,\n' +
        '      property damage, or a stolen Vehicle, the Rider shall file a report with the local police station within 24 hours.\n' +
        '       The ',
    'RiderText34': 'Rider agrees that he/she is responsible and liable for any misuse, consequences, claims,\n' +
        '        demands, causes of action, losses, liabilities, damages, injuries, costs and expenses, penalties, fees,\n' +
        '        judgments, suits or disbursements of any kind or nature whatsoever related to a stolen or lost\n' +
        '        Vehicle. ',
    'RiderText35': 'Rider agrees that traffic violations, fines or impound charges are at the risk and expense of\n' +
        '      the Rider, including in connection with improper or unauthorized parking at the end of the rental period. Rider\n' +
        '      agrees and acknowledges that Zipp may cooperate with law enforcement to provide any information necessary as they\n' +
        '      may request or may otherwise be required.',
    'RiderText36': 'The Rider agrees to return the Vehicle to Zipp in\n' +
        '      the same condition in which it was rented.  Zipp reserves the right to charge the Rider for damage to the\n' +
        '      Vehicles caused by the Rider or others (including any vandalism), water damage, or theft, up to the value of the\n' +
        '      Vehicle plus administrative and processing fees. The Rider will not be responsible for normal wear and tear\n' +
        '      incurred in the ordinary use of the Vehicle.',
    'RiderText37': 'The Vehicle is an electric vehicle that requires periodic charging of its battery in order to operate. The Rider agrees to use and operate the\n' +
        '      Vehicle safely and prudently in light of the Vehicle being an electric vehicle and all of the limitations and\n' +
        '      requirements associated therewith. The Rider understands and agrees with each of the following:',
    'RiderText38': 'The level of charge power remaining in the Vehicle will decrease with use of the Vehicle(over both time and distance),\n' +
        '      and that as the level of charge power of the Vehicle decreases, the speed and other operational capabilities of\n' +
        '      the Vehicle may decrease (or cease in their entirety).',
    'RiderText39': 'The level of charging power in the Vehicle at the time the Rider initiates the rental or operation of the Vehicle is\n' +
        '      not guaranteed and will vary with each rental use.',
    'RiderText40': 'The rate of loss of charging power during the use of the Vehicle is not guaranteed and will vary based on the Vehicle,\n' +
        '      road conditions, weather conditions, and other factors.',
    'RiderText41': 'It is the Rider’s responsibility to check the level of charge power in the Vehicle and to ensure that it is adequate\n' +
        '      before initiating operation of the Vehicle.',
    'RiderText42': 'The distance and/or time that the Rider may operate the Vehicle before it loses charging power is never\n' +
        '      guaranteed.',
    'RiderText43': 'The Vehicle may run out of charging power and cease to operate at any time during the Rider’s rental of the Vehicle, including before\n' +
        '      reaching the Rider’s desired destination.',
    'RiderText44': 'If the Vehicle runs out of charging power during a rental, Rider shall conclude\n' +
        '      the ride in compliance with all terms of this Agreement.   ',
    'RiderText45': 'The Rider agrees that he/she is\n' +
        '        responsible and liable for any misuse, consequences, claims, demands, causes of action, losses, liabilities,\n' +
        '        property or fire or other damages, injuries, costs, and expenses, penalties, fees, judgments, suits, or\n' +
        '        disbursements of any kind or nature whatsoever related to the Rider charging or attempting to charge the\n' +
        '        Vehicle.  By choosing to charge a Vehicle, the Rider assumes full and complete responsibility for all\n' +
        '        related risks, dangers, and hazards, and the Rider agrees that ',
    'RiderText46': 'Zipp and all other Released Persons\n' +
        '      (defined below in Section 15) are not responsible for any injury, damage, or cost caused by the Rider with respect\n' +
        '      to any person or property, including the Vehicle itself, directly or indirectly related to the charging of the\n' +
        '      Vehicle.',
    'RiderText47': 'Unless otherwise instructed by Zipp in writing, to activate Services with the App, the\n' +
        '      Rider must use a smartphone or any other (mobile) device that meets the technical requirements for and is\n' +
        '      compatible with the App. Certain functions of the App, such as the possibility to unlock, rent and end the rental\n' +
        '      of the Vehicle require that the App has an active network connection. The Rider is responsible for the\n' +
        '      availability and costs of mobile data communication services. The Rider is also responsible for ensuring that the\n' +
        '      mobile device has adequate battery capacity. Zipp shall not be responsible if the Rider is unable to unlock, use\n' +
        '      or end the ride of the Vehicle as a result of lost or interrupted network connection, mobile device malfunction,\n' +
        '      or depleted battery. The Rider shall remain responsible for and Zipp may charge the Rider all costs(including\n' +
        '      rental fees) incurred until the ride is ended.',
    'RiderText48': 'The Rider may use the Vehicle in accordance with the pricing\n' +
        '      described in the App, which may include a ride start fee, fees based on distance or time (with time rounded up to\n' +
        '      the nearest minute), and/or a required minimum fee. Pricing is subject to change.  In each case, fees and\n' +
        '      other charges may include applicable taxes and other local government charges.  The Rider will be charged\n' +
        '      (through credit card, debit card, or another agreed payment method) the amount of the fees as described in this\n' +
        '      Agreement and the App, including any recurring payment you choose. The Rider agrees that Zipp may, in its sole\n' +
        '      discretion, pay all traffic tickets, impound fees, fines and/or charges on the Rider’s behalf directly to the\n' +
        '      appropriate authority or applicable party. If Zipp is required to pay and/or process such fees or associated\n' +
        '      costs, the Rider agrees that Zipp may charge him/her for the amount paid plus a reasonable administration charge\n' +
        '      for dealing with these matters. The Rider will be provided notice of any such costs or fees. In the event Zipp\n' +
        '      uses a third party collection and/or administrative agent to resolve any tickets, damages, infringements of law or\n' +
        '      of this Agreement, fines and/or penalties, the Rider agrees to pay all costs and collection fees including, but\n' +
        '      not limited to, administrative and legal costs to such agent upon demand without protest.',
    'RiderText49': 'Zipp may, in its sole discretion, create referral and/or promotional codes (“Promo Codes”) that may be used for\n' +
        '      discounts or credits on Services or other features or benefits provided by Zipp, subject to any additional terms\n' +
        '      that Zipp establishes. The Rider agrees that Promo Codes: (i) must be used for the intended audience and purpose,\n' +
        '      and in a lawful manner; (ii) may not be duplicated, sold or transferred in any manner, unless expressly permitted\n' +
        '      by Zipp;(iii) may be disabled by Zipp at any time for any reason without liability to Zipp;(iv) may only be used\n' +
        '      pursuant to the specific terms that Zipp establishes for such Promo Code; (v) are not valid for cash; (vi) may be\n' +
        '      subject to quantity or value limits; and (vii) may expire prior to your use. Zipp reserves the right to withhold\n' +
        '      or deduct credits or other features or benefits obtained through the use of the referral system or Promo Codes by\n' +
        '      you or any other user in the event that it determines or believes that the use of the referral system or use or\n' +
        '      redemption of the Promo Code was in error, fraudulent, illegal, or otherwise in violation of this Agreement or\n' +
        '      specific terms applicable to such Promo Codes.',
    'RiderText50': 'The Zipp Promo Code referred to as the "Zipp Pass" allows riders in the United Kingdom only rent a vehicle for a set period given certain operating\n' +
        '      parameters for a fixed price. These operating parameters are as follows. The "1 day Zipp Pass" allows riders rent\n' +
        '      the vehicle a maximum of 6 times for a maximum period of 60 minutes for each ride for a fixed price of £10 over\n' +
        '      one 24 hour period only. The "7 day Zipp Pass" allows riders rent a vehicle a maximum of 4 times per day for\n' +
        '      a maximum period of 40 minutes for each ride over a 7 day period for a fixed price of £25.',
    'RiderText51': 'The Rider agrees that the Rider will deactivate the Vehicle rental within 90 minutes of renting a Vehicle. The Rider\n' +
        '      may then rent again. The Rider agrees that he/she is solely responsible for being aware of the length of any\n' +
        '      elapsed ride time.  After return of the Vehicle, the Rider will be charged the accumulated rental charges.',
    'RiderText52': 'Rental time will be calculated from the moment of unlocking the\n' +
        '      Vehicle through the App until the Rider receives the confirmation through the App that the ride has been ended. If\n' +
        '      the Rider ends the ride incorrectly, this may result in the ride not being terminated. If the ride is not ended\n' +
        '      properly, the ride will continue and the Rider will continue to be charged. If the Rider has technical issues\n' +
        '      terminating a ride for any reason, this should be reported through the App immediately. Failure to report an issue\n' +
        '      in terminating a ride may result in continued charges.',
    'RiderText53': 'Vehicles not returned (with the ride concluded) within 48 hours will be considered lost or stolen, and the Rider may be charged\n' +
        '      up to the value of the Vehicle plus administrative and processing fees. Zipp may also charge additional service\n' +
        '      fees for rentals in excess of 24 hours where the Vehicle is not lost or stolen.',
    'RiderText54': 'To be registered to use the Services, the Rider must provide\n' +
        '      a valid credit, debit card or prepaid card number and expiration date or other valid payment method information.\n' +
        '       The Rider represents and warrants to Zipp that Rider is authorized to use any credit, debit or prepaid card\n' +
        '      or other payment method information the Rider furnishes to Zipp. By providing a payment method, the Rider\n' +
        '      agrees that Zipp is authorized to charge for the ride and any other fees incurred by the Rider under this\n' +
        '      Agreement, including all applicable governmental and regulatory charges and applicable sales and other taxes.',
    'RiderText55': 'When the Rider provides a payment method or in accordance with Zipp policies, our system will attempt to verify the\n' +
        '      information you entered. We may do this by processing an authorization hold, which is a standard practice.  We\n' +
        '      do not charge you in connection with this authorization hold. If the payment method expires and the Rider does not\n' +
        '      update the information or cancels his/her account, the Rider authorizes Zipp to continue billing, and the Rider\n' +
        '      will remain responsible for any uncollected amounts. Zipp reserves the right to retry billing all payment\n' +
        '      method(s) on file after any failed billing attempt. The Rider will remain liable for all such amounts and all\n' +
        '      costs incurred in connection with the collection of these amounts, including, without limitation, bank overdraft\n' +
        '      fees, collection agency fees, reasonable attorneys’ fees, and arbitration or court costs.',
    'RiderText56': 'If the Rider disputes any charge on the Rider’s payment method, then the Rider must contact Zipp within 10 business\n' +
        '      days from the end of the month with the disputed charge, and provide to Zipp all trip information that is\n' +
        '      necessary to identify the disputed charge, such as the date of the trip and the approximate starting and ending\n' +
        '      times of the ride associated with the disputed charge. The Rider agrees to immediately inform Zipp of all\n' +
        '      changes relating to the payment method.',
    'RiderText57': 'If the Rider has agreed to make automatic or recurring payments, such payments will continue until the Rider cancels or his/her account is\n' +
        '      terminated. Zipp may continue to charge your payment method for any additional fees or charges incurred under this\n' +
        '      Agreement.',
    'RiderText58': 'If the Rider is unable to return a Vehicle to a valid area (i.e.\n' +
        '      deactivate the Vehicle on private property, a locked community, or another unreachable area),and request that the\n' +
        '      Vehicle be picked up by Zipp staff, Zipp, at its sole discretion, may charge the Rider a pick-up fee.  If any\n' +
        '      Vehicle accessed under the Rider’s account is abandoned without notice, the Rider will be responsible for all trip\n' +
        '      fees until the Vehicle is recovered and deactivated, plus a service charge to recover the Vehicle.  Fees are\n' +
        '      subject to change.',
    'RiderText59': 'Before each use of a Vehicle, the Rider shall conduct a basic\n' +
        '      safety inspection of the Vehicle, which includes inspecting the following: (i) trueness of the wheels;(ii) safe\n' +
        '      operation of the throttle, all brakes and lights; (iii) goodcondition of the frame; (iv) sufficient battery charge\n' +
        '      power; and (iv) any sign of damage, unusual or excessive wear, or other open and obvious mechanical\n' +
        '      problem/maintenance need.  The Rider agrees not to ride the Vehicle if there are any noticeable issues, and\n' +
        '      to immediately notify customer service to alert Zipp of any problems.',
    'RiderText60': 'A Vehicle may be deemed lost or stolen if (a) the Vehicle is not returned within 24 consecutive hours, (b) the\n' +
        '      Vehicle’s GPS unit is disabled, (c) the Vehicle is parked on unauthorized private property, in a locked area, or\n' +
        '      in any other non-public space for more than ten minutes after a ride ends, (d) the Vehicle moves more than thirty\n' +
        '      feet after a rental has ended and Zipp believes such movement was not caused by another Rider or authorized third\n' +
        '      party, or (e)other facts and circumstances that suggest to Zipp in its reasonable, good faith determination that a\n' +
        '      Vehicle has been lost or stolen.  Zipp and the Rider agree that the last Rider of a Vehicle shall be\n' +
        '      responsible for a lost or stolen Vehicle unless facts and circumstances suggest otherwise to Zipp in its\n' +
        '      reasonable, good faith determination. If Zipp deems a Vehicle lost or stolen, Zipp shall have the authority\n' +
        '      to take any and all actions it deems appropriate (with respect to the last Rider of a Vehicle or otherwise),\n' +
        '      including (without limitation) obtaining restitution and other appropriate compensation and damages and filing a\n' +
        '      police report with local authorities. The Rider agrees the data generated by Zipp’s systems is conclusive\n' +
        '      evidence of the period of use of a Vehicle by a Rider. The Rider agrees to report Vehicle disappearance or\n' +
        '      theft to Zipp immediately or as soon as possible.',
    'RiderText61': 'The Rider shall comply with all applicable helmet laws and regulations. Zipp recommends that the Rider wears a helmet meeting\n' +
        '      appropriate standards that has been properly sized, fitted and fastened according to the manufacturer’s\n' +
        '      instructions.',
    'RiderText62': 'Zipp and all other Released Persons (defined below in Section 15) do not represent or\n' +
        '        warrant the quality or safety characteristics of any helmet, and the Rider agrees that none of the Released\n' +
        '        Persons is liable for any injury suffered by the Rider while using any of the Services, whether or not the Rider\n' +
        '        is wearing a helmet at the time of injury',
    'RiderText63': '. The Rider',
    'RiderText64': ' assumes all risk of not wearing\n' +
        '        a helmet or other protective gear.',
    'RiderText65': '  The Rider may need to take additional safety measures or\n' +
        '      precautions not specifically addressed in this Agreement. ',
    'RiderText66': 'The Rider agrees that Zipp does not provide or maintain places to ride Vehicles, and that Zipp does not guarantee that there\n' +
        '      will always be a safe place to ride a Vehicle.  Roads, vehicle lanes, and vehicle routes may become dangerous\n' +
        '      due to weather, traffic, or other hazards.',
    'RiderText67': 'The Rider agrees that Zipp is not a common carrier. Alternative means of public and private transportation are available to the general public\n' +
        '      and to the Rider individually, including public buses and rail service, taxis, and pedestrian paths. Zipp provides\n' +
        '      Vehicles only as a convenience, and such rental availability is intended to be used only by those persons who are\n' +
        '      able and qualified to operate a Vehicle on their own and who have agreed to all terms and conditions of this\n' +
        '      Agreement.',
    'RiderText68': 'At any time and from time to time, and without the Rider’s\n' +
        '      consent, Zipp may unilaterally terminate the Rider’s right to use the Services, in Zipp’s sole discretion and\n' +
        '      without any notice or cause.  The Rider may terminate the Rider’s use of the Services at any time; provided,\n' +
        '      however, that (i) no refund will be provided by Zipp, (ii) the term of this Agreement continues in accordance with\n' +
        '      this Agreement, and (iii) the Rider may still be charged any applicable additional fees in accordance with this\n' +
        '      Agreement. This Agreement remains in full force and effect, in accordance with its terms and conditions, after any\n' +
        '      termination of the Rider’s right to use any of the Services, regardless of how the Agreement is\n' +
        '      terminated.',
    'RiderText69': 'The Rider understands and agrees that all personal information that is held by Zipp (or shared with Zipp by Technology Services Provider in accordance with its\n' +
        '      Privacy Policy) and that pertains to Riders, including all names, addresses, phone numbers, email addresses,\n' +
        '      passwords, payment information, and other information, will be kept by Zipp in accordance with its privacy policy.',
    'RiderText70': 'In order to use our services, we are required to access location data on your mobile\n' +
        '      device. Please see our Privacy Policy for details on how we process personal data.',
    'RiderText71': 'Any notice under this Agreement can be delivered to Zipp by emailing',
    'RiderText72': ' com or by post to Nova UCD, University College\n' +
        '      Dublin, Stillorgan Road, Belfield, Dublin. ',
    'RiderText73': 'This Agreement shall be governed by and construed in accordance with the laws of Ireland and\n' +
        '      the parties hereto submit to the jurisdiction of the Irish courts.',
    'RiderText74': 'Support is available via the App to address any concerns the Rider may have regarding your\n' +
        '      use of a Vehicle and/or this Agreement. The parties shall use their best efforts through this support process to\n' +
        '      settle any dispute, claim, question, or disagreement and engage in good faith negotiations which shall be a\n' +
        '      condition to either party initiating mediation, arbitration, or a lawsuit.',
    'RiderText75': 'No waiver of any breach of any provision of this Agreement is a waiver of any other breach or\n' +
        '      of any other provision of this Agreement. The provisions of this Agreement are independent of and separable from\n' +
        '      each other, and no provision shall be affected or rendered invalid or unenforceable by virtue of the fact that for\n' +
        '      any reason any other or others of them may be invalid or unenforceable in whole or in part. All rights and\n' +
        '      remedies granted under or referred to in this Agreement are cumulative and nonexclusive, and resort to one does\n' +
        '      not preclude the availability or applicability of another or to any other right or remedy provided by law.',
    'RiderText76': 'In exchange for the Rider being allowed to use Services, Vehicles, and other equipment or\n' +
        '      related information provided by Zipp, Rider agrees to fully release, indemnify, and hold harmless Zipp and all of\n' +
        '      its owners, managers, affiliates, employees, contractors, fleet management service providers, officers, directors,\n' +
        '      shareholders, agents, representatives, successors, assigns, and every property owner or Zipp with whom Zipp has\n' +
        '      contracted to operate Services and all of such parties’ owners, managers, affiliates, employees, contractors,\n' +
        '      officers, directors, shareholders, agents, representatives, successors, and assigns (collectively, the “Released\n' +
        '      Persons”)from liability for all “Claims” arising out of or in any way related to Rider’s use of the Services,\n' +
        '      Vehicles, App, or related equipment, including, but not limited to, those Claims based on Released Persons’\n' +
        '      alleged negligence, breach of contract, and/or breach of express or implied warranty, except for Claims based on\n' +
        '      Released Persons’ gross negligence or willful misconduct.  Such releases are intended to be general and\n' +
        '      complete releases of all Claims. ',
    'RiderText77': '“Claims” means, collectively, any and all claims, injuries, demands, liabilities, disputes, causes of action (including statutory, contract, negligence, or other tort theories),\n' +
        '      proceedings, obligations, debts, liens, fines, charges, penalties, contracts, promises, costs, expenses (including\n' +
        '      fees, whether incurred at trial, on appeal, or otherwise), damages (including but not limited to, for personal\n' +
        '      injury, wrongful death, property damage, and injury to the Rider or to third parties, consequential, compensatory,\n' +
        '      or punitive damages), or losses (whether known, unknown, asserted, unasserted, fixed, conditional, or contingent)\n' +
        '      that arise from or relate to (a) any of the Services, including any of the Vehicles, placement, equipment,\n' +
        '      maintenance, related information, App, this Agreement or (b) Rider’s use of any of the foregoing. ',
    'RiderText78': 'To the fullest extent permitted by law, and as to Rider’s use of any of the Services, Vehicles, App, or related\n' +
        '      equipment, Zipp and all other Released Persons disclaim all express and implied warranties, including warranties\n' +
        '      of merchantability and fitness for a particular purpose.  All of the Services, Vehicles, App, and related\n' +
        '      equipment are provided “as is” and “as available,” and the Rider relies on them at the Rider’s own risk. ',
    'RiderText79': 'The Rider is aware that the Rider’s use of any of the Services, Vehicles, App, and related equipment involves obvious\n' +
        '      and not-so-obvious risks, dangers, and hazards that may result in injury or death to Rider or others and damage to\n' +
        '      property, and that such risks, dangers, and hazards cannot always be predicted or avoided.  Risks, dangers,\n' +
        '      and hazards, include, but are not limited to:',
    'RiderText80': 'vehicles and other objects;',
    'RiderText81': 'pedestrians;',
    'RiderText82': 'traffic;',
    'RiderText83': 'Vehicle or component malfunction;',
    'RiderText84': 'road conditions;',
    'RiderText85': 'weather conditions;',
    'RiderText86': 'failure to follow applicable laws regarding use and/or operation of the Vehicle;',
    'RiderText87': 'commission of any of the prohibited acts listed in Section 2.8;',
    'RiderText88': 'failure to perform the required safety check pursuant to Section 4.1;',
    'RiderText89': 'failure to wear a helmet where required by law; and ',
    'RiderText90': 'negligent acts or omissions by Zipp, any other Released Person, Rider, or third party. ',
    'RiderText91': 'The Rider is solely and fully responsible for the safe operation of Vehicle at all times. Rider agrees that Vehicles are machines that may\n' +
        '      malfunction, even if the Vehicle is properly maintained and that such malfunction may cause injury. Rider\n' +
        '      assumes full and complete responsibility for all related risks, dangers, and hazards. ',
    'RiderText92': 'To the fullest extent permitted by law, this release and hold harmless agreement includes any and all Claims related to or\n' +
        '      arising from the sole or partial negligence of Zipp, the Released Parties, or any other party.',
}