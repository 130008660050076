export default {
    'HeaderNav1': '申请停车位',
    'HeaderNav2': '向 Zipp 反馈',
    'HeaderNav3': '联系方式',
    'HomeHeroTitle1': '机动性',
    'HomeHeroTitle2': '做得好',
    'HomeHeroText': 'Zipp 是爱尔兰领先的微型移动设备提供商。下载 Zipp 应用程序，找到 Zipp 并开始 Zipping！',
    'HomeAboutText1': '共享微型机动性有其缺陷。',
    'HomeAboutText2': '我们的 "正确交通 "方法可以解决这些问题。',
    'HomeAboutText3': '我们知道如何满足城市所有利益相关者的需求。',
    'HomeHowTitle': '如何使用 Zipp',
    'HomeHowText1': '下载应用程序',
    'HomeHowText2': '查找滑板车',
    'HomeHowText3': '扫描二维码，Zipp 出发！',
    'HomeCommTitle': 'Zipp 有什么与众不同之处？',
    'HomeCommText1': '我们通过创新的共享微型交通方式，确保在可持续性、安全性和停车方面取得最佳成果。这种方法基于几个关键原则。',
    'HomeCommText2': '以社区为中心',
    'HomeCommText3': '可持续性第一',
    'HomeCommText4': '人人享有微型交通',
    'HomeCommText5': '无外包',
    'HomeCommText6': '世界级技术',
    'HomeCommText7': '可持续增长',
    'HomeCommBtn': '显示更多',
    'HomeContactTitle': '联系我们',
    'HomeContactText1': '',
    'HomeContactText2': '贝尔菲尔德创新园',
    'HomeContactText3': '',
    'HomeContactText4': '',
    'HomeContactText5': '爱尔兰',
    'HomeContactText6': '姓名',
    'HomeContactText7': '电子邮件',
    'HomeContactText8': '留言',
    'FooterCopyRight': '版权所有 © 2021 Zipp Mobility - 保留所有权利。',
    'FooterTitle1': '投诉政策',
    'FooterTitle2': '骑手协议',
    'ParkingTitle': '请求停车位',
    'ParkingText1': '我们一直在为我们的踏板车寻找新的停车位。 如果您想建议我们放置踏板车的位置，请在下面留下您的详细信息和您建议的位置。 我们建议使用',
    'ParkingText2': '三词地址',
    'ParkingText3': '来识别您建议的位置！ 请注意，所有停车位都必须经过包括多个当地利益相关者在内的审批流程，因此我们无法保证所有请求都会得到满足。\n',
    'ParkingPlaceholder1': '姓名（必填）',
    'ParkingPlaceholder2': '电子邮件（必填）',
    'ParkingPlaceholder3': '三词地址位置或地址以及拟议停车位的描述（必填）',
    'ParkingPlaceholder4': '任何其他评论（可选）',
    'SubmitError': '哎呀！提交表单时出现问题。',
    'SubmitSuccess': '非常感谢。您的投稿已收到！',
    'SubmitBtn': '提交',
    'ExportTitle': '向zipp反馈',
    'ExportText1': '我们希望为社区中的每个人提供尽可能安全的服务。 如果您目睹电动滑板车滥用、不安全骑行，或在 Zipp 停车场外发现一辆滑板车 - 我们希望了解相关情况。 请在下面输入事件的详细信息，我们将尽快采取行动。 谢谢您的合作！',
    'ExportPlaceholder1': '姓名（必填）',
    'ExportPlaceholder2': '电子邮件（必填）',
    'ExportPlaceholder3': '问题描述：地点、时间、滑板车登记号。',
    'ExportPlaceholder4': '任何其他评论（可选）',
    'PolicyTitle': '投诉政策',
    'PolicyTitle1': '何时使用本政策',
    'PolicyTitle2': '如何提交投诉',
    'PolicyTitle3': '您的投诉中应该包含什么内容',
    'PolicyTitle4': '处理您的投诉',
    'PolicyTitle5': '结果',
    'PolicyTitle6': '把事情做好',
    'PolicyTitle7': '学习经验',
    'PolicyTitle8': '我们对您的期望',
    'PolicyText1': '当您向我们投诉时，我们通常会按照下面解释的方式做出回应。 有时，您可能关心的事项不是由我们决定的（例如，在我们当地政府合作伙伴或国家政府监管机构控制下的事项），然后我们会建议您如何表达您的担忧。',
    'PolicyText2': '您可以通过以下任一方式进行投诉：',
    'PolicyText3': '您可以使用我们网站www.zippmobility.com 上的表格进行投诉。',
    'PolicyText4': '您可以使用我们应用程序中的客户支持功能。',
    'PolicyText5': '您可以发送电子邮件至info@zippmobility.com。',
    'PolicyText6': '请记住注明您的姓名、地址和电话号码（以及电子邮件，如果适用）以及您是否代表其他人行事。',
    'PolicyText7': '简要描述您的投诉内容，并说明相关地点、车号、日期和时间（如果适用）。',
    'PolicyText8': '从最重要的问题开始列出您的具体问题。',
    'PolicyText9': '明确您希望实现的目标（例如退款、道歉、解释等）。',
    'PolicyText10': '说明您首选的沟通方式。',
    'PolicyText11': '处理您的投诉需要这些详细信息。',
    'PolicyText12': '‍我们将在两个工作日内正式确认您的投诉，并告知您我们打算如何处理。 我们将公开、诚实地处理您的投诉。 如果您代表其他人提出投诉，我们需要他们同意您代表他们行事。',
    'PolicyText13': '在我们调查您的投诉后，我们将告知您我们的结论。 我们将解释我们如何以及为何得出结论。 如果我们发现齐普有过错，我们会告诉您发生了什么以及为什么发生。',
    'PolicyText14': '如果我们做得不好，我们会努力改正。 如果您因我们的错误而失败，我们将尽力将您恢复到如果我们做对的话本应处于的位置。',
    'PolicyText15': '我们认真对待您的投诉，并努力从我们所犯的错误中吸取教训。 我们的高级管理团队定期考虑所有投诉的摘要以及任何严重投诉的详细信息。 如果需要改变，我们将制定一项行动计划，列出我们将做什么、谁将做以及我们计划何时做。',
    'PolicyText16': '在遇到麻烦或痛苦的时候，有些人可能会表现得不正常。 可能存在令人不安或痛苦的情况导致投诉。 我们不会仅仅因为某人强势或坚定就将其行为视为不可接受。 我们相信所有投诉人都有权得到倾听、理解和尊重。 然而，我们也认为我们的员工也享有同样的权利。 因此，我们希望您在与我们打交道时保持礼貌和礼貌。 我们不会容忍攻击性或虐待性行为、无理要求或无理坚持。',
    'RiderTitle': 'Zipp 骑士协议',
    'RiderTitle1': '定义',
    'RiderTitle2': '服务的使用',
    'RiderTitle3': '付款及费用',
    'RiderTitle4': '附加使用条款',
    'RiderTitle5': '通知',
    'RiderTitle6': '适用法律',
    'RiderTitle7': '争议解决',
    'RiderTitle8': '弃权和可分割性',
    'RiderTitle9': '发布；免责声明；',
    'RiderTitle10': '风险承担',
    'RiderText1': '本《乘客协议》（“',
    'RiderText2': '协议',
    'RiderText3': '”）管辖位于 Nova UCD, University College Dublin, Stillorgan Road, Belfield, Dublin 的 ZIPP MOBILITY LIMITED（“Zipp”）与您（用户）（“乘客”）之间关于租赁和使用的合同关系 电动汽车（“',
    'RiderText4': '车辆',
    'RiderText5': '”）（“',
    'RiderText6': '服务',
    'RiderText7': '”）。',
    'RiderText8': 'Zipp 同意根据此处规定的条款和条件出租车辆，且乘客明确同意承租车辆。',
    'RiderText9': '“应用程序”是指由 Zipp 拥有和管理的与服务相关的移动应用程序；',
    'RiderText10': '“Zipp 设备”是指 Zipp 拥有的与服务相关的任何设备，包括车辆；',
    'RiderText11': 'Zipp 和骑手是本协议的唯一当事人。 除非本协议明确允许：',
    'RiderText12': '乘客是唯一承租人，并全权负责遵守本协议中包含的所有条款和条件； ',
    'RiderText13': '一旦乘客激活车辆，该车辆只能由乘客使用；',
    'RiderText14': '骑手不得允许其他人使用骑手已激活的车辆。',
    'RiderText15': '乘客声明并证明他/她已年满 16 岁，并拥有有效的临时或正式驾驶执照。',
    'RiderText16': '乘客声明并证明，他/她熟悉车辆的操作，具有相当的能力且身体健康，可以乘坐车辆，并已查看 Zipp 通过应用程序和/或其他材料提供的安全材料。 通过选择乘坐车辆，乘客承担任何伤害或医疗状况的所有责任和风险。 乘客全权负责确定各种条件（包括但不限于雨、雾、雪、冰雹、冰、热或雷暴）是否会导致驾驶车辆出现危险。',
    'RiderText17': '乘客同意，车辆及其所附的任何 Zipp 设备始终是 Zipp 和/或其出租人/许可人的专有财产。 骑手不得以任何方式拆卸、书写或以其他方式修改、修理或损毁车辆、车辆的任何部分或其他 Zipp 设备。 乘客不得以任何方式在车辆上的任何贴纸上书写、剥落或以其他方式修改或污损。 未经 Zipp 明确书面许可，乘客不得将车辆或其他 Zipp 设备用于任何广告或其他商业目的。',
    'RiderText18': '乘客同意并承认车辆可能无法全年 365 天、每周 7 天、每天 24 小时提供服务。 车辆必须在营业时间内并在下述最长租赁期限内租赁。 车辆数量有限，且无法保证车辆可用性。 乘客同意 Zipp 可以随时要求乘客归还车辆。',
    'RiderText19': '乘客同意不在允许的服务区域之外使用、操作和/或乘坐车辆，并进一步同意除非 Zipp 明确授权，否则不会移动或运输车辆。',
    'RiderText20': '乘客同意遵守与车辆的使用、骑行、停车、充电和/或操作相关的所有法律，包括乘客操作车辆所在地区的所有当地法律以及与车辆相关的规则和法规，包括任何 头盔法。 乘客还同意在使用服务时以礼貌和尊重他人的方式行事。',
    'RiderText21': '骑手同意以下内容：',
    'RiderText22': 'Zipp 建议不要在携带或持有公文包、背包、包或其他可能改变平衡、增加额外重量或损害车辆安全操作的物品时操作车辆。 如果骑手选择使用此类物品，他/她将自行承担风险； Zipp 建议骑手确保该物品紧贴其身体或以其他方式固定，并且不会妨碍骑手安全操作车辆的能力。',
    'RiderText23': '乘客不得在车辆的车把上放置任何物品，例如背包或包。',
    'RiderText24': '乘坐车辆时，乘客不得使用任何移动电话、短信设备、便携式音乐播放器或其他可能分散乘客安全操作车辆注意力的设备。\n',
    'RiderText25': '乘客不得在酒精、毒品、药物或其他可能影响其安全操作车辆能力的物质的影响下操作车辆。',
    'RiderText26': '乘客不得在车辆上搭载第二人或儿童。',
    'RiderText27': '骑手只能使用 Zipp 提供的锁定机制。 除非按照 Zipp 的说明，乘客不得在车辆上添加另一把锁或锁定车辆。',
    'RiderText28': '车辆不得停放在禁止停车位。 车辆不得停放在未经授权的私人财产、上锁区域、阻挡通行权或任何其他未经批准的非公共空间。 如果车辆有被撞倒的危险，乘客不应将车辆停放在交通繁忙的区域。',
    'RiderText29': '车辆必须停放在可见的空间，并使用支架保持直立位置。',
    'RiderText30': '骑手同意他/她不会将车辆用于赛车、山地骑行、特技或特技骑行。 骑手同意，他/她不会在未铺砌的道路、水中（超出正常城市骑行范围）或任何禁止、非法和/或对他人造成滋扰的地点操作和/或使用车辆。 乘客同意，他/她不会将车辆用于出租或取酬，也不会违反任何法律、条例或法规。',
    'RiderText31': '乘客不得超过车辆的最大重量限制。',
    'RiderText32': '乘客不得篡改、试图未经授权访问或以其他方式使用本协议规定以外的服务。',
    'RiderText33': '乘客必须尽快向 Zipp 报告任何事故、碰撞、损坏、人身伤害、交通违规或车辆被盗或丢失。 发生人身伤害、财产损失、车辆被盗等事故的，乘客应当在24小时内向当地派出所报案。',
    'RiderText34': '骑手同意，他/她对任何滥用、后果、索赔、要求、诉讼原因、损失、责任、损害、伤害、成本和费用、处罚、费用、判决、诉讼或任何类型的支出负责，并承担责任。',
    'RiderText35': '与被盗或丢失车辆有关的任何性质。 乘客同意，交通违规、罚款或扣押费用由乘客承担，包括在租赁期结束时与不当或未经授权的停车有关的风险和费用。 Rider 同意并承认，Zipp 可以与执法部门合作，根据执法部门的要求或其他要求提供任何必要的信息。',
    'RiderText36': '乘客同意将车辆按租赁时的状态归还给 Zipp。 Zipp 保留向乘客收取因乘客或其他人造成的车辆损坏（包括任何故意破坏）、水灾或盗窃的权利，最高可达车辆价值加上管理费和处理费。 乘客不对车辆正常使用过程中发生的正常磨损负责。',
    'RiderText37': '该车辆是一种电动车辆，需要定期为其电池充电才能运行。 鉴于车辆是电动车辆以及与之相关的所有限制和要求，乘客同意安全、谨慎地使用和操作车辆。 骑手理解并同意以下各项：',
    'RiderText38': '车辆中剩余的充电电量将随着车辆的使用（随着时间和距离的推移）而减少，并且随着车辆充电电量的降低，车辆的速度和其他操作能力可能会降低（ 或完全停止）。',
    'RiderText39': '骑手开始租赁或操作车辆时车辆的充电电量水平不受保证，并且会因每次租赁用途而异。',
    'RiderText40': '车辆使用过程中的充电电量损失率不予保证，并且会因车辆、路况、天气条件和其他因素而异。',
    'RiderText41': '乘客有责任在开始操作车辆之前检查车辆的充电电量并确保电量充足。',
    'RiderText42': '乘客在车辆失去充电功率之前可以操作车辆的距离和/或时间无法得到保证。',
    'RiderText43': '在骑手租赁车辆期间，包括在到达骑手期望的目的地之前，车辆可能随时耗尽充电电量并停止运行。',
    'RiderText44': '如果车辆在租赁期间耗尽充电电量，乘客应按照本协议的所有条款结束行程。 ',
    'RiderText45': '乘客同意他/她对任何误用、后果、索赔、要求、诉讼原因、损失、责任、财产或火灾或其他损害、伤害、成本和开支、罚款、费用、判决、诉讼负责并承担责任。 ，或与乘客充电或试图为车辆充电相关的任何种类或性质的支出。 通过选择对车辆充电，乘客对所有相关风险、危险和危害承担全部责任，并且乘客同意 ',
    'RiderText46': 'Zipp 和所有其他免责人员（定义见下文第 15 条）不对任何伤害、损坏负责 ，或乘客对任何人或财产（包括车辆本身）造成的与车辆充电直接或间接相关的费用。',
    'RiderText47': '除非 Zipp 另有书面指示，否则要通过应用程序激活服务，乘客必须使用智能手机或满足应用程序技术要求并与应用程序兼容的任何其他（移动）设备。 应用程序的某些功能（例如解锁、租赁和结束车辆租赁的可能性）要求应用程序具有有效的网络连接。 乘客负责移动数据通信服务的可用性和费用。 骑手还负责确保移动设备具有足够的电池容量。 如果由于网络连接丢失或中断、移动设备故障或电池耗尽而导致乘客无法解锁、使用或结束车辆的乘坐，Zipp 不承担任何责任。 乘客仍应对骑行结束前产生的所有费用（包括租赁费）负责，并且 Zipp 可以向乘客收取所有费用（包括租赁费）。',
    'RiderText48': '乘客可以按照应用程序中描述的定价使用车辆，其中可能包括骑行起始费、基于距离或时间的费用（时间四舍五入到最接近的分钟）和/或所需的最低费用。 定价可能会发生变化。 在每种情况下，费用和其他收费可能包括适用的税费和其他地方政府收费。 我们将（通过信用卡、借记卡或其他商定的付款方式）向乘客收取本协议和应用程序中所述的费用，包括您选择的任何定期付款。 乘客同意 Zipp 可自行决定代表乘客直接向有关当局或适用方支付所有交通罚单、扣押费、罚款和/或收费。 如果 Zipp 需要支付和/或处理此类费用或相关费用，乘客同意 Zipp 可以向他/她收取所支付的金额以及处理这些事务的合理管理费。 乘客将收到有关任何此类费用或费用的通知。 如果 Zipp 使用第三方收款和/或行政代理来解决任何罚单、损害、违反法律或本协议、罚款和/或处罚，乘客同意支付所有费用和收款费用，包括但不限于 根据要求，在没有抗议的情况下向该代理人支付行政和法律费用。',
    'RiderText49': 'Zipp 可以自行决定创建推荐和/或促销代码（“促销代码”），这些代码可用于 Zipp 提供的服务或其他功能或福利的折扣或积分，但须遵守 Zipp 制定的任何附加条款。 乘客同意促销代码： (i) 必须以合法方式用于目标受众和目的； (ii) 不得以任何方式复制、出售或转让，除非 Zipp 明确许可；(iii) Zipp 可以随时以任何理由禁用 Zipp，且无需对 Zipp 承担任何责任；(iv) 只能根据 Zipp 为此类促销代码制定的具体条款； (v) 不能兑换现金； (vi) 可能受到数量或价值限制； (vii) 可能在您使用之前过期。 如果 Zipp 确定或认为使用推荐系统或使用或兑换您或任何其他用户通过使用推荐系统或促销代码获得的积分或其他功能或好处，则保留扣留或扣除的权利 促销代码的错误、欺诈、非法或以其他方式违反本协议或适用于此类促销代码的特定条款。',
    'RiderText50': 'Zipp 促销代码（称为“Zipp Pass”）允许英国的乘客仅在给定某些操作参数的情况下以固定价格在设定期限内租用车辆。 这些操作参数如下。 “1 天 Zipp Pass”允许乘客最多租用车辆 6 次，每次乘坐时间最长为 60 分钟，固定价格为 10 英镑，仅限 24 小时内。 “7 天 Zipp Pass”允许乘客每天最多租车 4 次，7 天内每次骑行最长 40 分钟，固定价格为 25 英镑。',
    'RiderText51': '乘客同意乘客在租用车辆后 90 分钟内停用车辆租赁。 然后骑手可以再次租赁。 乘客同意他/她全权负责了解任何已用骑行时间的长度。 归还车辆后，乘客将被收取累积的租赁费用。',
    'RiderText52': '租赁时间将从通过应用程序解锁车辆的那一刻开始计算，直到乘客通过应用程序收到骑行已结束的确认为止。 如果乘客错误地结束行程，可能会导致行程无法终止。 如果行程未正确结束，行程将继续，并且乘客将继续被收取费用。 如果乘客因任何原因出现技术问题而终止行程，应立即通过应用程序报告。 未能报告终止行程的问题可能会导致继续收费。',
    'RiderText53': '48 小时内未归还（行程结束）的车辆将被视为丢失或被盗，乘客可能会被收取车辆价值加上管理费和手续费。 如果车辆未丢失或被盗，Zipp 还可能对超过 24 小时的租赁收取额外服务费。',
    'RiderText54': '要注册使用服务，乘客必须提供有效的信用卡、借记卡或预付卡卡号以及到期日期或其他有效的付款方式信息。 乘客向 Zipp 声明并保证，乘客有权使用乘客向 Zipp 提供的任何信用卡、借记卡或预付卡或其他支付方式信息。 通过提供付款方式，乘客同意 Zipp 有权收取乘客根据本协议产生的行程费用和任何其他费用，包括所有适用的政府和监管费用以及适用的销售税和其他税费。',
    'RiderText55': '当乘客提供付款方式或符合 Zipp 政策时，我们的系统将尝试验证您输入的信息。 我们可以通过处理授权保留来做到这一点，这是标准做法。 我们不会向您收取与此授权保留相关的费用。 如果付款方式到期且乘客未更新信息或取消其帐户，则乘客授权 Zipp 继续计费，且乘客仍将对任何未收到的金额负责。 Zipp 保留在任何计费尝试失败后重新尝试对存档的所有付款方式进行计费的权利。 附加者仍将对所有此类金额以及与收取这些金额相关的所有费用负责，包括但不限于银行透支费、托收代理费、合理的律师费以及仲裁或法庭费用。',
    'RiderText56': '如果乘客对其付款方式的任何费用有争议，则乘客必须在该月底后 10 个工作日内就有争议的费用联系 Zipp，并向 Zipp 提供识别有争议费用所需的所有行程信息，例如 作为行程日期以及与争议费用相关的行程的大致开始和结束时间。 骑手同意立即通知 Zipp 与付款方式相关的所有变更。',
    'RiderText57': '如果乘客同意自动或定期付款，则此类付款将持续到乘客取消或其帐户被终止为止。 Zipp 可能会继续通过您的付款方式收取本协议项下产生的任何额外费用或收费。',
    'RiderText58': '如果乘客无法将车辆归还至有效区域（即在私人财产、上锁的社区或其他无法到达的区域停用车辆），并请求 Zipp 工作人员领取车辆，Zipp 将自行决定 ，可能会向乘客收取接机费。 如果乘客帐户下访问的任何车辆在未经通知的情况下被遗弃，则乘客将负责支付车辆恢复并停用之前的所有行程费用，以及恢复车辆的服务费。 费用可能会发生变化。',
    'RiderText59': '每次使用车辆之前，骑手应对车辆进行基本安全检查，包括检查以下内容：(i) 车轮的真实性；(ii) 油门、所有制动器和车灯的安全操作；以及 (iii) 框架状况良好； (iv) 充足的电池充电电量； (iv) 任何损坏、异常或过度磨损的迹象，或其他明显的机械问题/维护需要。 乘客同意，如果出现任何明显问题，则不再乘坐车辆，并立即通知客户服务部，提醒 Zipp 任何问题。',
    'RiderText60': '如果 (a) 连续 24 小时内未归还车辆，(b) 车辆的 GPS 装置被禁用，(c) 车辆停放在未经授权的私人财产上、上锁区域，或 行程结束后在任何其他非公共空间停留超过十分钟，(d) 租赁结束后车辆移动超过三十英尺，并且 Zipp 认为此类移动不是由其他乘客或授权第三方造成的，或者 ( e) 表明 Zipp 合理、善意地认定车辆已丢失或被盗的其他事实和情况。 Zipp 和乘客同意，车辆的最后一名乘客应对车辆丢失或被盗负责，除非事实和情况表明 Zipp 合理、善意的决定有其他情况。 如果 Zipp 认为车辆丢失或被盗，Zipp 有权采取其认为适当的任何及所有行动（针对车辆的最后一个乘客或其他），包括（但不限于）获得归还和其他适当的补偿和损害赔偿 并向地方当局提交警方报告。 乘客同意 Zipp 系统生成的数据是乘客使用车辆期限的确凿证据。 乘客同意立即或尽快向 Zipp 报告车辆失踪或被盗的情况。',
    'RiderText61': '骑手应遵守所有适用的头盔法律和法规。 Zipp 建议骑手佩戴符合适当标准的头盔，并根据制造商的说明进行适当尺寸、安装和紧固。',
    'RiderText62': 'Zipp 和所有其他免责人员（定义见下文第 15 条）不代表或保证任何头盔的质量或安全特性，且骑手同意，任何免责人员均不对骑手在使用任何头盔时遭受的任何伤害负责。 服务，无论骑手在受伤时是否佩戴头盔。',
    'RiderText63': '骑手',
    'RiderText64': '承担不戴头盔或其他防护装备的所有风险。',
    'RiderText65': '乘客可能需要采取本协议中未具体提及的额外安全措施或预防措施。',
    'RiderText66': '乘客同意 Zipp 不提供或维护乘坐车辆的场所，并且 Zipp 不保证始终有安全的场所来乘坐车辆。 道路、车道和车辆路线可能会因天气、交通或其他危险而变得危险。',
    'RiderText67': '骑士同意 Zipp 不是一家公共承运人。 公众和乘客可以选择其他公共和私人交通方式，包括公共巴士和铁路服务、出租车和人行道。 Zipp 提供车辆只是为了方便起见，此类租赁服务仅供有能力且有资格自行操作车辆并同意本协议所有条款和条件的人员使用。',
    'RiderText68': '在任何时间和不时，未经乘客同意，Zipp 可以自行决定单方面终止乘客使用服务的权利，无需任何通知或原因。 乘客可以随时终止乘客对服务的使用； 但是，前提是 (i) Zipp 不会提供退款，(ii) 本协议的期限按照本协议继续有效，并且 (iii) 乘客仍可能需要根据本协议收取任何适用的额外费用 。 在乘客使用任何服务的权利终止后，无论本协议如何终止，根据其条款和条件，本协议仍然具有完全效力。',
    'RiderText69': '乘客理解并同意，Zipp 持有的（或技术服务提供商根据其隐私政策与 Zipp 共享的）所有与乘客相关的个人信息，包括所有姓名、地址、电话号码、电子邮件地址、密码、 Zipp 将根据其隐私政策保存付款信息和其他信息。',
    'RiderText70': '为了使用我们的服务，我们需要访问您移动设备上的位置数据。 有关我们如何处理个人数据的详细信息，请参阅我们的隐私政策。',
    'RiderText71': '本协议项下的任何通知均可通过发送电子邮件至',
    'RiderText72': '或邮寄至 Nova UCD, University College Dublin, Stillorgan Road, Belfield, Dublin 的方式发送给 Zipp',
    'RiderText73': '本协议应受爱尔兰法律管辖并根据爱尔兰法律解释，本协议双方须接受爱尔兰法院的管辖。',
    'RiderText74': '通过应用程序可以获得支持，以解决乘客对您使用车辆和/或本协议可能存在的任何疑虑。 双方应通过此支持流程尽最大努力解决任何争议、索赔、问题或分歧，并进行善意谈判，这应成为任何一方启动调解、仲裁或诉讼的条件。',
    'RiderText75': '放弃对任何违反本协议任何条款的行为并不构成放弃对任何其他违反行为或本协议任何其他条款的行为。 本协议的条款是相互独立且可分离的，任何条款均不得因以下事实而受到影响或变得无效或无法执行：无论出于何种原因，其中任何其他条款或其他条款可能全部或部分无效或无法执行 。 根据本协议授予或提及的所有权利和补救措施都是累积的和非排他性的，并且诉诸其中一项权利和补救措施并不排除另一项权利和补救措施的可用性或适用性，也不排除法律规定的任何其他权利或补救措施。',
    'RiderText76': '作为允许乘客使用 Zipp 提供的服务、车辆和其他设备或相关信息的交换，乘客同意完全释放、赔偿 Zipp 及其所有所有者、经理、附属公司、员工、承包商、车队并使其免受损害。 管理服务提供商、管理人员、董事、股东、代理人、代表、继承人、受让人以及 Zipp 与其签订合同运营服务的每位财产所有者或 Zipp 以及所有此类各方的所有者、经理、附属机构、员工、承包商、管理人员， 董事、股东、代理人、代表、继任者和受让人（统称为“免责人员”）对因乘客使用服务、车辆、应用程序或相关设备而产生或以任何方式与之相关的所有“索赔”承担责任 ，包括但不限于基于被免责人涉嫌疏忽、违反合同和/或违反明示或默示保证的索赔，基于被免责人重大过失或故意不当行为的索赔除外。 此类发布旨在全面且完整地发布所有索赔。',
    'RiderText77': '“索赔”统指任何及所有索赔、伤害、要求、责任、争议、诉讼原因（包括法定、合同、疏忽或其他侵权理论）、程序、义务、债务、留置权、罚款、指控、罚金 、合同、承诺、成本、开支（包括费用，无论是在审判、上诉还是其他情况下产生的费用）、损害赔偿（包括但不限于人身伤害、非正常死亡、财产损失以及对骑手或第三方的伤害） 因 (a) 任何服务（包括任何车辆）引起或与之相关的损失（无论是已知的、未知的、断言的、未断言的、固定的、有条件的或或有的）， 放置、设备、维护、相关信息、应用程序、本协议或 (b) 骑手对上述任何内容的使用。',
    'RiderText78': '在法律允许的最大范围内，对于乘客对任何服务、车辆、应用程序或相关设备的使用，Zipp 和所有其他免责人员不承担所有明示和暗示的保证，包括适销性和特定用途适用性的保证 。 所有服务、车辆、应用程序和相关设备均按“原样”和“可用”提供，乘客自行承担依赖它们的风险。',
    'RiderText79': '乘客意识到，乘客对任何服务、车辆、应用程序和相关设备的使用涉及明显和不那么明显的风险、危险和危害，可能导致乘客或其他人受伤或死亡以及财产损失 ，并且此类风险、危险和危害并不总是可以预测或避免。 风险、危险和危害包括但不限于：',
    'RiderText80': '车辆和其他物体；',
    'RiderText81': '行人；',
    'RiderText82': '交通;',
    'RiderText83': '车辆或部件故障；',
    'RiderText84': '道路状况；',
    'RiderText85': '天气状况;',
    'RiderText86': '未能遵守有关车辆使用和/或操作的适用法律；',
    'RiderText87': '实施第 2.8 条中列出的任何禁止行为；',
    'RiderText88': '未能按照第 4.1 节执行所需的安全检查；',
    'RiderText89': '未按法律要求佩戴头盔； 和',
    'RiderText90': 'Zipp、任何其他免责人员、骑手或第三方的疏忽行为或不作为。',
    'RiderText91': '乘客始终对车辆的安全操作承担全部责任。 乘客同意，车辆是可能发生故障的机器，即使车辆保养得当，并且此类故障可能会造成伤害。 乘客对所有相关风险、危险和危害承担全部责任。',
    'RiderText92': '在法律允许的最大范围内，本免责协议包括与 Zipp、免责方或任何其他方的单独或部分疏忽相关或由其单独或部分疏忽引起的任何及所有索赔。',
}