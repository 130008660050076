import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/parking',
    name: 'parking',
    component: () => import('@/views/ParkingView.vue')
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/ReportView.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('@/views/PolicyView.vue')
  },
  {
    path: '/rider',
    name: 'rider',
    component: () => import('@/views/RiderView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    if(to.hash) {
      const formEl = document.getElementById('wf-form-Email-Form')
      formEl.scrollIntoView({
        behavior: 'smooth'
      })
    }else{
      return {x: 0, y: 0}
    }
  }
})

export default router
